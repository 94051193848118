import React from "react";

import Lander from "../components/landers";
import { HomeLanderContent } from "../content/landers";
import { FoodDemo } from "../images";

const Home = () => {
  return <Lander obj={HomeLanderContent} videoSrc={FoodDemo.src} />;
};

export default Home;
